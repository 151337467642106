<template>
  <div v-if="job">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <div class="d-flex flex-row justify-space-between">
              <div>
                <div class="text-h5 primary--text font-weight-bold mb-2">Review and post your job ad</div>
                <div>Check you’re happy with the ad details, choose how public you want the ad to be, then select ‘Confirm and Post’ to start finding your ideal candidates.</div>
              </div>
            </div>
            <v-divider class="my-3"></v-divider>

            <!-- FIRST PAGE -->
            <div class="mb-3">
              <span :class="label">What is the job you are hiring for?</span> {{ job.title }}
            </div>
            
            <!-- TEACHING LEVEL -->
            <div class="mb-3">
              <span :class="label">What teaching level do you need?</span> {{ job.position }}
            </div>

            <!-- SKILLS -->
            <div :class="label">What key skills and qualifications do you need?</div>
            <div class="d-flex flex-row mt-3 mb-4">
              <v-chip
                small
                class="mr-2"
                v-for="(skill, index) in job.skillSet"
                :key="index"
                >{{ skill }}</v-chip
              >
            </div>

            <!-- JOB TYPE -->
            <div class="mb-3">
              <span :class="label">Job Type</span> {{ job.type }}
            </div>
            
            <!-- SCHOOL LEVEL -->
            <div class="mb-3">
              <span :class="label">School level</span> {{ job.yearLevel }}
            </div>

            <!-- JOB DETAILS -->
            <div :class="label">Job Details</div>
            <div class="mt-3 mb-4 text-pre-line">{{ job.description }}</div>
            
            <!-- FILE ATTACHMENTS -->
            <div v-if="status.detailFiles.length" class="mb-3">
              <div :class="label">Attachments</div>

              <div class="d-flex gap-5">
                <v-chip
                  v-for="(file, i) in status.detailFiles"
                  :key="i"
                  small
                >
                  {{ file.name }} <span class="caption ml-1">({{ file.size | fileSize }})</span>
                </v-chip>
              </div>
            </div>

            <!-- REFERENCES -->
            <div class="mb-3">
              <span :class="label">Job Reference</span> {{ job.reference }}
            </div>
            
            <v-btn
              @click="$emit('set', 'title-description')"
              class="mt-3 mb-3"
              text
              small
            >
              <v-icon left class="add-skill">
                mdi-pencil-outline
              </v-icon>
              <span class="text-decoration-underline text-none ls-0 font-weight-regular">Edit this section</span>
            </v-btn>
            
            <!-- SECOND PAGE -->
            <v-divider class="my-3"></v-divider>
            
            <!-- CONTRACT LENGTH -->
            <div class="mb-3">
              <span :class="label">How long is the job for?</span> {{ job.contractLength }}
            </div>
            
            <!-- CONTRACT DATES -->
            <div class="mb-3">
              <span :class="label">Contract Dates</span>
            </div>

            <div 
              v-if="job.contractDates && job.contractDates.length"
              class="d-flex gap-5"
            >
              <v-chip 
                v-for="(range, i) in job.contractDates"
                :key="i"
                small
              >
                {{ range[0] | simpleDate }} - {{ range[1] | simpleDate }}
              </v-chip>
            </div>            

            <v-btn
              @click.prevent="$emit('set', 'talent-expertise')"
              class="mt-3 mb-3"
              text
              small
            >
              <v-icon left class="add-skill">
                mdi-pencil-outline
              </v-icon>
              <span class="text-decoration-underline text-none ls-0 font-weight-regular">Edit this section</span>
            </v-btn>
            
            <!-- THIRD PAGE -->

            <v-divider class="my-3"></v-divider>
            
            <template v-if="job.question1">
              <span :class="label">Question 1:</span>
              <p class="mt-2">{{ job.question1 }}</p>
            </template>
            
            <template v-if="job.question2">
              <span :class="label">Question 2:</span>
              <p class="mt-2">{{ job.question2 }}</p>
            </template>
            
            <template v-if="job.question3">
              <span :class="label">Question 3:</span>
              <p class="mt-2">{{ job.question3 }}</p>
            </template>

            <v-btn
              @click="$emit('set', 'salary')"
              class="mt-3 mb-3"
              small
              text
            >
              <v-icon left class="add-skill">
                mdi-pencil-outline
              </v-icon>
              <span class="text-decoration-underline text-none ls-0 font-weight-regular">Edit this section</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      label: 'heading-font mb-1 default-size primary--text font-weight-bold',
      caption: 'caption'
    }
  },
  
  props: {
    job: {
      type: Object,
      required: true,
    },
    status: Object
  }
}
</script>