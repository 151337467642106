<template>
  <div v-if="user" id="top" class="flex-grow-1">
    <UserTitle hideBack>
      <template v-slot:actions>
        <div class="d-flex align-center">
           <v-btn
              @click="$router.go(-1)"
              class="rounded mr-5"
              color="grey lighten-2"
              outlined
              small
              fab
            >
              <v-icon size="25" color="dark">mdi-chevron-left</v-icon>
            </v-btn>
            <h2 class="text-h4 font-weight-black">
              {{ job.id ? 'Update' : 'Create A' }} Job Ad
            </h2>
        </div>
      </template>
    </UserTitle>

    <!-- Filters -->
    <div>
      <v-alert
        type="warning"
        :value="!$store.getters['pricing/withActiveSub'] && user.role !== 'admin'"
        border="left"
        text
      >
        You are not yet subscribed. Please subscribe
        <router-link :to="{ name: 'Subscription' }">here</router-link> so you
        can start creating jobs.
      </v-alert>
      
      <v-alert
        :value="!Object.keys(school).length && !$store.state.schools.status.getting"
        type="warning"
        border="left"
        text
      >
        You haven't added your school details yet. Add your school details <router-link :to="{ name: 'School' }">here</router-link>.
      </v-alert>

      <preloader v-if="$store.state.schools.status.getting" message="Processing your request..." />

      <v-row v-if="($store.getters['pricing/withActiveSub'] && Object.keys(school).length) || user.role == 'admin'">
        <v-col cols="12" class="job-section">
          <div>
            <v-form ref="jobDetails" v-if="user">
              <!-- Title & Description Card -->
              <v-card
                v-if="isActive('title-description')"
                class="mb-6 shadow-lg"
                ref="titledescription"
              >
                <v-card-text class="pl-9 pr-9">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-overlay
                        v-if="emptySchool"
                        :absolute="true"
                        :opacity="1"
                      >
                        <v-btn to="/school" class="primary">
                          Add School First
                        </v-btn>
                      </v-overlay>
                      <v-row>
                        <v-col cols="12" md="12">
                          <div class="d-flex flex-row justify-space-between">
                              <span class="title primary--text font-weight-black">Tell us what you're looking for</span>
                          </div>
                          <v-divider class="my-6"></v-divider>
                          <v-form ref="jobtitledescription">
                            <div :class="label">What is the job you are hiring for?*</div>
                            <div :class="caption">This will be the job title that candidates see - e.g. English teacher Term 2</div>
                            <v-text-field
                              v-model="job.title"
                              :rules="[rules.required]"
                              class="mt-3"
                              outlined
                            ></v-text-field>

                            <div :class="label">What type of person do you need?*</div>
                            <div :class="caption">Select the position required for this role</div>
                            <v-select
                              v-model="job.position"
                              :items="orderBy(positions, 'order', 'asc')"
                              :rules="[rules.required]"
                              item-text="name"
                              item-value="name"
                              class="mt-3"
                              multiple
                              outlined
                            ></v-select>

                            <div :class="label">What key skills and qualifications do you need?*</div>
                            <div :class="caption">Select what you require candidates to be qualified to teach</div>
                            <v-combobox
                              v-model="job.skillSet"
                              :items="skillsets"
                              :rules="[required]"
                              :search-input.sync="searchInput"
                              @change="jobUpdated"
                              append-icon=""
                              class="mt-4"
                              deletable-chips
                              small-chips
                              multiple
                              outlined
                            ></v-combobox>

                            <div :class="label">Job Type*</div>
                            <v-radio-group :rules="[rules.required]" v-model="job.type">
                              <v-radio
                                v-for="(option, i) in ['Contract (part-time)', 'Contract (full-time)', 'Casual', 'Full-time']"
                                :key="i"
                                :label="option" 
                                :value="option"
                              ></v-radio>
                            </v-radio-group>

                            <div :class="label">School Level*</div>
                            <v-radio-group v-model="job.yearLevel" :rules="[rules.required]">
                              <v-radio
                                v-for="(option, i) in ['Early Childhood', 'Primary', 'Secondary', 'Vocational']"
                                :key="i"
                                :label="option" 
                                :value="option"
                              ></v-radio>
                            </v-radio-group>
                            
                            <div v-if="job.attachments && job.attachments.length">
                              <div :class="label">Attachments</div>

                              <div class="d-flex gap-5 my-5 flex-wrap">
                                <v-chip 
                                  v-for="(attachment, i) in job.attachments" 
                                  :key="i" 
                                 color="grey lighten-2"
                                 small
                                >
                                  <span class="mr-3">{{ attachment | fileName }}</span>
                                  
                                  <v-btn
                                    @click="deleteFile(attachment)"
                                    color="grey darken-1"
                                    class="ml-2" 
                                    width="18"
                                    height="18"
                                    depressed
                                    small
                                    right
                                    fab
                                  >
                                    <v-icon color="grey lighten-2" x-small>mdi-trash-can-outline</v-icon>
                                  </v-btn>
                                </v-chip>
                              </div>
                            </div>

                            <div :class="label">Job Details</div>
                            <div :class="caption">Enter a short description about the role here and/or upload any relevant files</div>
                            
                            <div 
                              v-if="status.detailFiles.length" 
                              class="d-flex gap-5 mt-5 flex-wrap"
                            >
                              <v-chip
                                v-for="(file, i) in status.detailFiles"
                                :key="i"
                                color="grey lighten-2"
                                small
                              >
                                <span 
                                  class="primary--text"
                                  :style="{textOverflow: 'ellipsis', display: 'inline-block', overflow: 'hidden'}"
                                >{{ file.name }}</span> <span class="caption ml-1">({{ file.size | fileSize }})</span>

                                <v-btn 
                                  @click="$store.commit('jobs/removeFile', i)" 
                                  color="grey darken-1"
                                  class="ml-2" 
                                  width="18"
                                  height="18"
                                  depressed
                                  small
                                  right
                                  fab
                                ><v-icon color="grey lighten-2" x-small>mdi-trash-can</v-icon></v-btn>
                              </v-chip>
                            </div>

                            <v-file-input
                              ref="uploadFiles"
                              v-model="files"
                              @change="addFiles($event)"
                              prepend-inner-icon="mdi-paperclip"
                              placeholder="Select a file"
                              prepend-icon=""
                              class="mt-5"
                              hide-details
                              multiple
                              outlined
                            ></v-file-input>

                            <v-textarea
                              v-model="job.description"
                              label="Enter a short description about the role here"
                              class="mt-4"
                              auto-grow
                              outlined
                            >
                            </v-textarea>

                            <div :class="label">Job Reference</div>
                            <div :class="caption">Enter reference for internal use only</div>
                            <v-text-field
                              v-model="job.reference"
                              class="mt-3"
                              outlined
                            ></v-text-field>
                          </v-form>
                          <v-divider class="mb-6"></v-divider>
                          <v-btn
                            color="accent gradient"
                            href="#top"
                            @click="
                              nextTab({
                                currentSection: 'Job Title and Description',
                                nextSection: 'talent-expertise',
                              })
                            "
                          >
                            Continue
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- End Title & Description Card -->
              <!-- Talent Expertise Card -->
              <v-sheet v-if="isActive('talent-expertise')">
                <v-card
                  class="mb-6 shadow-lg"
                  id="talent-expertise"
                >
                  <v-card-text class="pl-9 pr-9">
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12">
                            <span class="text-h5 primary--text font-weight-black">Say when and for how long</span>
                            <v-divider class="my-6"></v-divider>
                            <v-form ref="talentExpertise">
                              <div :class="label">How long is the job for?*</div>
                              <v-radio-group v-model="job.contractLength" :rules="[rules.required]">
                                <v-radio
                                  v-for="(option, i) in ['0-1 week', '1-2 weeks', '2-4 weeks', '4+ weeks']"
                                  :key="i"
                                  :label="option" 
                                  :value="option"
                                ></v-radio>
                              </v-radio-group>

                              <div class="mb-3" :class="label">Contract Dates*</div>

                              <div 
                                v-if="job.contractDates && job.contractDates.length"
                                class="d-flex gap-5 flex-wrap"
                              >
                                <v-chip
                                  v-for="(range, i) in job.contractDates"
                                  class="mb-5"
                                  :key="i"
                                  :color="i == editingRange ? 'var(--v-accent-base)' : 'grey lighten-2'"
                                  :dark="i == editingRange"
                                  :disabled="editingRange !== null && editingRange !== i"
                                  small
                                >
                                  <div class="d-flex align-center">
                                    <span :style="{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block' }">
                                      {{ range[0] | simpleDate }} - {{ range[1] | simpleDate }}
                                    </span>
                                    <div v-if="editingRange == null" class="ml-3">
                                      <v-btn @click="editRange(range, i)" class="mr-1" color="grey darken-1" width="18" height="18" depressed fab><v-icon color="grey lighten-2" x-small>mdi-pencil</v-icon></v-btn>
                                      <v-btn @click="$store.commit('job/removeContractRange', range)" color="grey darken-1" width="18" height="18" depressed fab><v-icon color="grey lighten-2" x-small>mdi-trash-can</v-icon></v-btn>
                                    </div>
                                  </div>
                                </v-chip>
                              </div>

                              <div class="d-flex align-center"> 
                                <p>Add or edit date range</p>
                                <v-spacer></v-spacer>
                                <v-btn v-if="editingRange !== null" @click="cancelEdit()" color="accent" text small>Cancel Edit</v-btn>
                              </div>

                              <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="dateRange"
                                transition="scale-transition"
                                min-width="auto"
                                offset-y
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-combobox
                                    v-model="dateRange"
                                    :rules="!job.contractDates || !job.contractDates.length ? [rules.required] : []"
                                    prepend-inner-icon="mdi-calendar"
                                    ref="rangeDatePicker"
                                    v-bind="attrs"
                                    color="white"
                                    v-on="on"
                                    readonly
                                    outlined
                                  ></v-combobox>
                                </template>
                                <v-date-picker
                                  v-model="dateRange"
                                  scrollable
                                  no-title
                                  multiple
                                  range
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      menu = false
                                      cancelEdit()
                                    "
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.menu.save(dateRange)
                                      addDateRange()
                                    "
                                  >
                                    OK
                                  </v-btn>
                                </v-date-picker>
                              </v-menu>
                              
                            </v-form>
                            <v-divider class="my-5"></v-divider>
                            <v-btn
                              class="mr-3"
                              outlined
                              @click="setActive('title-description')"
                            >
                              Back
                            </v-btn>
                            <v-btn
                              color="accent gradient"
                              href="#top"
                              @click="
                                nextTab({
                                  currentSection: 'Talent Expertise',
                                  nextSection: 'salary',
                                })
                              "
                            >
                              Continue
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-sheet>
              <!-- End Talent Expertise Card -->
              <!-- Salary Card -->
              <v-card v-if="isActive('salary')" class="mb-6 shadow-lg">
                <v-card-text class="pl-9 pr-9">
                  <v-form ref="salary">
                    <div class="text-h5 primary--text font-weight-black mb-2">What do you want to know from candidates?</div>
                    <div>Help shortlist by receiving responses on up to three tailored questions from all applicants</div>
                    <v-divider class="my-6"></v-divider>

                    <div class="heading-font mb-3 default-size primary--text font-weight-bold">Question 1:</div>
                    <v-text-field
                      v-model="job.question1"
                      placeholder="e.g. Any availability issues or special conditions to note?"
                      outlined
                    ></v-text-field>
                    
                    <div class="heading-font mb-3 default-size primary--text font-weight-bold">Question 2:</div>
                    <v-text-field
                      v-model="job.question2"
                      placeholder="e.g. Key strengths you’ll bring to the role?"
                      outlined
                    ></v-text-field>
                    
                    <div class="heading-font mb-3 default-size primary--text font-weight-bold">Question 3:</div>
                    <v-text-field
                      v-model="job.question3"
                      outlined
                    ></v-text-field>

                    <v-btn
                      class="mr-3"
                      outlined
                      @click.prevent="setActive('talent-expertise')"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="accent gradient"
                      href="#top"
                      @click="
                        nextTab({
                          currentSection: 'Salary',
                          nextSection: 'review',
                        })
                      "
                    >
                      Continue
                    </v-btn>
                    
                  </v-form>
                </v-card-text>
              </v-card>
              <!-- End Salary Card -->
              <!-- Review Card -->
              <v-card v-if="isActive('review')" class="mb-6 shadow-lg">
                <v-card-text class="pl-9 pr-9">
                  <review-job :job="job" :status="status" @set="setActive" />

                  <v-divider class="mb-3"></v-divider>

                  <div class="d-flex align-center gap-10 mb-7">
                    <v-switch
                      v-model="job.private"
                      :true-value="true"
                      :false-value="false"
                      :value="false"
                      persistent-hint
                      class="mb-5"
                      color="primary"
                      inset
                      dense
                    ></v-switch>

                    <div>
                      <div :class="label">Keep Job Private</div>
                      <div :class="caption">Choose whether to keep your ad private and invite select candidates to apply via the TeacherFinder search database or cast your net wide by posting your ad now for the marketplace to see. You will always be able to change the setting of an open job later if you wish.</div>
                    </div>
                  </div>

                  <template v-if="$route.query.template">
                    <v-btn
                      color="accent gradient"
                      @click.prevent="validateJobDetails('store')"
                      :loading="status.adding"
                    >
                      Confirm & Post This Job Ad
                    </v-btn>

                    <v-btn
                      v-if="job.id"
                      @click.prevent="validateJobDetails('update')"
                      :disabled="status.adding"
                      class="ml-2"
                      depressed
                    >
                      Update Existing Template
                    </v-btn>
                    
                    <v-btn
                      v-if="job.id"
                      @click.prevent="validateJobDetails('template')"
                      :disabled="status.adding"
                      class="ml-2"
                      depressed
                    >
                      Save As New Template
                    </v-btn>
                  </template>

                  <template v-else>
                    <v-btn
                      v-if="!job.ref"
                      color="accent gradient"
                      @click.prevent="validateJobDetails('store')"
                      :loading="status.adding"
                    >
                      Confirm & Post This Job Ad
                    </v-btn>
                    <v-btn
                      v-if="job.ref"
                      color="accent gradient"
                      @click.prevent="validateJobDetails('update')"
                      :loading="status.adding"
                    >
                      Confirm & Update This Job Ad
                    </v-btn>
                    
                    <v-btn
                      @click.prevent="validateJobDetails('template')"
                      :disabled="status.adding"
                      class="ml-2"
                      depressed
                    >
                      Save As Template
                    </v-btn>
                  </template>
                </v-card-text>
              </v-card>
              <!-- End Review Card -->
            </v-form>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- End Filters -->
  </div>
</template>

<script>
import locations from '@/assets/json/au_locations.json'
import { mapState, mapActions } from "vuex"
import ReviewJob from './ReviewJob'
import orderBy from 'lodash/orderBy'
import rules from "@/rules"
import "animate.css"
import { intersection } from 'lodash'

export default {
  name: "Job",

  metaInfo: {
    title: "Create New Job Ad",
  },

  data() {
    return {
      rules,
      orderBy,
      menu: false,
      menuend: false,
      activeItem: "title-description",
      checkSection: [],
      schoolItems: [],
      emptySchool: true,
      isValid: true,
      auLocation: locations,
      talentSearch: null,
      searchInput: null,
      label: 'heading-font mb-1 default-size primary--text font-weight-bold',
      caption: 'caption',
      enddate: [],
      dateRange: null,
      showPicker: false,
      editingRange: null,
      files: null,
    }
  },

  components: {
    ReviewJob
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      job: (state) => state.job.job,
      user: (state) => state.user.user,
      status: (state) => state.jobs.status,
      school: (state) => state.schools.school,
      schoolStatus: (state) => state.schools.status,
      skillsets: state => state.skillSets.skillsArray,
      positions: state => state.position.data,
    }),
  },

  /* -------------------------------------------------------------------------- */
  /*                                    WATCH                                   */
  /* -------------------------------------------------------------------------- */
  watch: {
    school: function (val) {
      if (val) {
        this.emptySchool = false
      }
    },

    'activeItem': function() {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    },

    'status.uploading': function (val) {
      if (!val) {
        this.jobCreated()
      }
    }
  },

  methods: {
    ...mapActions('job', [
      'modifyJob'
    ]),
    
    ...mapActions("jobs", ['storeJob', 'deleteFile', 'updateJobPost']),
    ...mapActions("schools", ["getUserSchool"]),
    ...mapActions('skillSets', ['getSkillSets']),
    ...mapActions('position', ['getPositions']),

    isActive(menuItem) {
      return this.activeItem === menuItem
    },

    setActive(menuItem) {
      this.activeItem = menuItem
    },

    addDateRange() {
      if (this.editingRange !== null) {
        this.$store.commit('job/updateRange', {
          index: this.editingRange,
          value: this.dateRange
        })
      }
      else {
        if (this.dateRange.length == 1) this.dateRange.push(this.dateRange[0])
        this.$store.commit('job/addContractRange', this.dateRange)
      }
      
      this.editingRange = null
      this.$refs.menu.save([])
    },

    editRange(range, i) {
      this.editingRange = i
      this.dateRange = Object.values(range)
    },

    addFiles($event) {
      this.$store.commit('jobs/addFile', $event)
      this.$nextTick(() => {
        this.files = null
      })
    },

    cancelEdit() {
      this.editingRange = null
      this.$refs.menu.save([])
      this.dateRange = null
    },

    nextTab(section) {

      if (section.currentSection === "Job Title and Description") {
        if (this.$refs.jobtitledescription.validate()) {
          this.setActive(section.nextSection)
          this.checkSection = []
          this.checkSection.push({
            section: "Job Title and Description",
            status: "checked",
          })
          this.isValid = true
        } 
        else {
          this.isValid = false
        }
      } 
      else if (section.currentSection === "Talent Expertise") {
        if (this.$refs.talentExpertise.validate()) {
          this.setActive(section.nextSection)
          this.checkSection = []
          this.checkSection.push({
            section: "Talent Expertise",
            status: "checked",
          })
          this.isValid = true
        } 
        else {
          this.isValid = false
        }
      } 
      else if (section.currentSection === "Location Details") {
        if (this.$refs.locationDetails.validate()) {
          this.setActive(section.nextSection)
          this.checkSection = []
          this.checkSection.push({
            section: "Location Details",
            status: "checked",
          })
          this.isValid = true
        } 
        else {
          this.isValid = false
        }
      } 
      else if (section.currentSection === "Salary") {
        if (this.$refs.salary.validate()) {
          this.setActive(section.nextSection)
          this.checkSection = []
          this.checkSection.push({
            section: "Salary",
            status: "checked",
          })
          this.isValid = true
        } 
        else {
          this.isValid = false
        }
      } 
      else if (section.currentSection === "Questionnaire") {
          this.setActive(section.nextSection)
          this.checkSection = []
          this.checkSection.push({
            section: "Questionnaire",
            status: "checked",
          })
      }
    },

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required."
      }
      return !!value || "Required."
    },

    validateJobDetails(action) {
      if (this.isValid) {
        let task

        if (action == 'store') task = Promise.all([this.storeJob()])
        else if (action == 'template') task = Promise.all([this.storeJob('template')])
        else if (action == 'update') task = Promise.all([this.updateJobPost()])

        task.then(() => {
          if (!this.status.uploading) this.jobCreated()
        })
      }
    },

    jobCreated() {
      this.$refs.jobDetails.reset()
      this.setActive("title-description")
      this.checkSection = []
      this.checkSection.push({
        section: "All",
        status: "empty",
      })

      this.$router.push({ name: "MyAccount"})
    },

    jobUpdated(values) {
      this.searchInput = null
      this.job.skillSet = intersection(values, this.skillsets)
    }
  },

  mounted() {
    if(this.$route.params.id){
      this.modifyJob({ id: this.$route.params.id, template: !!this.$route.query.template })
    }
    else if (!this.$store.state.job.status.duplicating) {
      this.$store.commit('job/initJob')
    }

    if (!this.schoolStatus.firstLoad) this.getUserSchool()

    if (this.school) {
      this.emptySchool = false
    }

    this.getSkillSets()
    this.getPositions()
    this.$store.commit('jobs/resetFile')
  },
}
</script>

<style scoped lang="scss">
#talent-expertise .add-skill {
  will-change: box-shadow !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 16px;
  border-radius: 100%;
}
</style>